/**************************************************************
* Fonts
**************************************************************/

/**
* CeraPro Regular
*/
@font-face {
    font-family: 'cera-pro-regular';
    font-path: '../fonts/cera-pro/regular/CeraPRO-Regular';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}


/**
* CeraPro Medium
*/
@font-face {
    font-family: 'cera-pro-medium';
    font-path: '../fonts/cera-pro/medium/CeraPRO-Medium';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}


/**
* CeraPro Bold
*/
@font-face {
    font-family: 'cera-pro-bold';
    font-path: '../fonts/cera-pro/bold/CeraPRO-Bold';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}


/* END OF DOCUMENT */
