/**************************************************************
* Buttons
**************************************************************/

/***************
* Primary button (solid gradient)
***************/
%btn--1 {
    @extend %ff--3;
    align-items: center;
    background-image: linear-gradient(132deg, #FC9909 0%, #F7BC08 100%);
	border-radius: 30px;
    color: #fff $c--2;
    cursor: pointer;
    border-radius: 33px;
    display: inline-flex;
    font-size: 15px;
    justify-content: center;
    min-height: 62px;
    padding: 19px 50px;
    transition: all .2s ease-in-out-quart;
    user-select: none;

    /* Hover */
    &:hover { background: #ff6312; }

}


/***************
* Secondary button (outline)
***************/
%btn--2 {
    @extend %ff--3;
    align-items: center;
    border: 1px solid $c--2;
	border-radius: 30px;
    color: $c--2 transparent;
    cursor: pointer;
    border-radius: 33px;
    display: inline-flex;
    font-size: 15px;
    justify-content: center;
    min-height: 62px;
    padding: 19px 50px;
    transition: all .2s ease-in-out-quart;
    user-select: none;

    /* Hover */
    &:hover { color: #fff $c--2; }

}


/***************
* Simple button
***************/
%btn--3 {
    @extend %ff--3;
    background: transparent url('../img/simple-button-icon.svg') no-repeat top 5px left 0 / 22px 20px;
    color: $c--1;
    cursor: pointer;
    display: inline-flex;
    font-size: 15px / 1.3em;
    justify-content: center;
    min-height: 20px;
    padding: 5px 0 5px 35px;
    transition: all .2s ease-in-out-quart;
    user-select: none;
}


/***************
* Carousel dots
***************/
%carousel-dots--1 {
	
	/* Dot */
	li.dot {
		background: transparent;
		margin: 0;
		opacity: 1;
		position: relative;
		size: 20px;

		&::before, &::after {
			border-radius: 100%;
			content: "";
			margin: auto;
			position: absolute 0;
			text-align: center;
			transition: all .1s ease-in-out-quart;
		}

		&::before {
			background: rgba($c--1,.12);
			size: 5px;
		}

		&::after {
			border: 1px solid $c--2;
			opacity: 0;
			size: 14px;
		}

		/* Selected */
		&.is-selected {

			&::before {
				background: $c--2;
				size: 10px;
			}

			&::after {
				opacity: 1;
				size: 20px;
			}
		}

	}

}


/***************
* Mobile menu trigger
***************/

/**
* Open
*/
%mobile-menu-trigger--open {
    size: 25px;
					
	/* Bottom line */
    &::after {
		background: #fff;
		content: "";
		display: block;
		margin: auto 0;
		position: absolute 0 * 0 0;
		size: 25px 2px;
		transform: translateY(5px);
		transform-origin: center;
		transition: width .4s ease-in-out-quart .4s;
	}

	/* Other lines */
    i, span {
		&::before, &::after {
			background: #fff;
			content: "";
			display: block;
			position: absolute;
			size: 0 2px;
		}
	}

	i {

		/* Open */
		&.open {
			&::before, &::after {
                backface-visibility: hidden;
                perspective: 1000;
				transition:
					background .3s ease-in-out-quart .4s,
					width .4s ease-in-out-quart .4s;
				width: 25px;
                will-change: auto;
			}
			&::before { left: 0; top: 4px; }
			&::after { right: 0; top: 10px; }
		}

		/* Closed */
		&.closed {
			display: block;
			overflow: visible;

			&::before, &::after {
                background: $c--2;
                backface-visibility: hidden;
				opacity: 0;
                perspective: 1000;
				position: absolute 11px * * 2px;
				transition: all .4s ease-in-out-quart 0s;
				width: 23px;
                will-change: auto;
			}

			&::before { transform: rotate(0deg); }
			&::after { transform: rotate(-90deg); }
		}

	}

}

/**
* Closed
*/
%mobile-menu-trigger--closed {

	/* Bottom line */
   &::after {
		transition-delay: 0s;
		width: 0;
	}

	/* Other lines */
	i {

		/* Open */
		&.open {
			&::before, &::after {
				transition-delay: 0s;
				width: 0;
			}
		}

		/* Closed */
		&.closed {
			&::before, &::after {
				opacity: 1;
				transition-delay: .2s;
			}
			&::before { transform: rotate(45deg); }
			&::after { transform: rotate(-45deg); }
		}

	}

}



/* END OF DOCUMENT */
