/**************************************************************
* Typography
**************************************************************/

/***************
* Families
***************/
%ff--1 { font-family: $ff--1; }
%ff--2 { font-family: $ff--2; }
%ff--3 { font-family: $ff--3; }


/***************
* Weights
***************/
$fw--light: 300;
$fw--reg: 400;
$fw--semi: 500;
$fw--semibold: 600;
$fw--bold: 700;
$fw--extrabold: 800;


/***************
* Text styles
- It is recommended to NOT include font families, margins, paddings, colours, weights or letter spacing.
- These should be applied in components or partials for greater flexibility.
***************/
%ts--1 {
    font-size: responsive 30px 52px;
    font-range: 420px 1280px;
    line-height: 1em;
}

%ts--2 {
    font-size: responsive 36px 62px;
    font-range: 420px 1280px;
    line-height: 1.17em;
}

%ts--3 {
    font-size: responsive 28px 42px;
    font-range: 420px 1280px;
    line-height: 1.09em;
}

%ts--4 {
    font-size: responsive 24px 32px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--5 {
    font-size: responsive 22px 26px;
    font-range: 420px 1280px;
    line-height: 1.325em;
}

%ts--6 {
    font-size: responsive 16px 22px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--7 { font-size: 13px / 1.475em; }

%ts--8 {
    font-size: responsive 16px 18px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--9 { font-size: 15px / 1.7em; }

%ts--10 { font-size: 30px / 1.475em; }

%ts--11 { font-size: 11px / 1.475em; }

%ts--12 { font-size: 16px / 1.55em; }

%ts--13 { font-size: 19px / 1.55em; }

%ts--14 { font-size: 20px / 1.55em; }

%ts--15 { font-size: 24px / 1.55em; }

%ts--16 { font-size: 18px / 1.475em; }

%ts--17 {
    font-size: responsive 38px 58px;
    font-range: 420px 1280px;
    line-height: .975em;
}

%ts--18 {
    font-size: responsive 20px 22px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--19 {
    font-size: responsive 32px 44px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--20 {
    font-size: responsive 40px 50px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--21 {
    font-size: responsive 18px 20px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--22 {
    font-size: responsive 16px 26px;
    font-range: 420px 1280px;
    line-height: 1.325em;
}

%ts--23 {
    font-size: responsive 13px 15px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}

%ts--24 {
    font-size: responsive 14px 18px;
    font-range: 420px 1280px;
    line-height: 1.475em;
}



/* END OF DOCUMENT */
