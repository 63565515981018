/**************************************************************
* Header
**************************************************************/


.impression-content-wrap-ecom{
	.text-sm {
	    font-size: 1rem;
	}
	p, li {
	    font-size: 1.2rem;
	}

	.btn.btn-md{
		border-radius: 33px;
		&.two-line {
			padding-top: 3px;
		}
	}

	.text-blue-darker{
		color:$c--1!important;
	}
	
	#card-errors{
		div{
			background: #ff0000bf;
    		color: white;
    		padding: 1em;
		}
	}


	.secure-form-redirect-form{

		padding:3rem 0;
		text-align: center;
		position: fixed;
		size:100%;
		height:100%;
		height:100vh;
		background: #1F3159;
		z-index: 9999;
		top:0;
		left:0;

		input[type="submit"]{
			display: none;
		}

		.inner-secure{
			position: absolute;
			top: 50%;
			left:50%;
			width: 80%;
			transform: translate(-50%,-50%);
			


			.top-area{
				max-width: 865px;
				margin:0 auto;
				background:#19294D;
				border-radius: 50px;
				padding: 2rem 1.5rem;
				text-align: center;

				.loader{
					size: 75px;
					background-image: url(../img/loader.gif);
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-size: contain;
					margin:0 auto;
				}
				h3{	
					font-size: 25px;
					font-weight: bold;
					color:white;
					margin: 1.5rem 0;
				}

				p{
					font-size: 14px;
					margin-bottom: 1rem;
					color:white;
				}
			}

		

			footer{
				display: flex;
				justify-content: center;
				align-items: center;
				div{
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-size: contain;
					margin:1rem;
				}
				.logo-one{
					background-image: url(../img/ne-logo.png);
					size:54px 45px;
				}

				.logo-two{
					background-image: url(../img/opayo-logo.png);
					size:90px 35px;
				}
			}

			@media(min-width: 1024px){
				.top-area{
					padding: 4rem 7.5rem;
					h3{	
						font-size: 35px;
						margin: 1.5rem 0;
					}
					p{
						font-size: 18px;
					}
				}

				footer{
					div{
						margin:2.5rem 2rem;
					}

					.logo-one{
						size:108px 90px;
					}

					.logo-two{
						size:180px 71px;
					}
				}
			}
		}




	} /* .secure-form-redirect-form */















	.card-block{
		background:#ECF0F4;
	    padding: 1rem;
	    border-radius: 5px;
	    margin-bottom: 10px;
		 max-width: 400px;

		.text-sm{
			font-size: 12px;
   			color: black;
   			margin: 1rem 0 0.5rem;
		}
	}



	.sage-card-fields{/*
		border: #ecf0f4 solid 1px;
	    padding: 1rem;
	    border-radius: 5px;
	    */
	    margin-bottom: 10px;
		background: url(../img/cards.png) no-repeat 0 5px;
		background-size: 165px auto;
		padding-top:40px;

		label{
			text-transform: uppercase;
			color: #000;
			font-size: 11px;
			line-height: 1.1em;
			margin-bottom: .5rem;
			display: block;
			margin-top: 1.2rem;
			position: relative;

			.required{
				color: red;
			    font-weight: 700;
			    border: 0!important;
			    text-decoration: none;
			    padding-left: 5px;
			}
		}
		input[type="number"],
		input[type="text"],
		select{
			display: block;
			width:100%;
			font-s1ze:15px;
			background:white;
			border: #ecf0f4 solid 1px;
			height: 45px;
			line-height: 45px;
			background:white;
			padding:0 15px;
			color:black;
			margin-bottom: 8px;
		}

		

		.dates{
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			.col{
				width:48%;
				margin:0;
				padding:0;
			}
		}
		input.cvc{
			max-width: 70px;
		}
	}













	.large-error{
		padding:0;
	}

}


.error-message{
	background: #ff0000bf;
	color: white;
	padding: 1em;
	font-size: 18px;
	margin: 1rem auto;
	font-weight: bold;
}


.stripe-processing{
	overflow: hidden;
	.stripe-payment-overlay{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		top:0;
		left:0;
		size: 100%;
		z-index: 9999999999999;
		background:rgba(0, 0, 0, 0.75);
		
		position: fixed;
		.container{
			max-width: 399px;
			padding:30px 1.5rem;
			margin:0 auto;
			background:white;
			background-position: top center;
		}

		.top{
			font-size: 25px;
			color: black;
			text-align: center;
			line-height: 1.2em;
			display: block;
		}

		.spinner{
			size:80px;
			background-size: 151px 151px;
			margin:3.5rem auto 1.5rem;
			background-position: 50%;
			background:url(../img/stripe-loader.svg) no-repeat;
			background-position: center center;
		}

		.bottom{
			font-size: 17px;
		    display: block;
		    color: grey;
		    text-align: center;
		}
	}
}
.stripe-payment-overlay{
	display: none;
}





.offset-energy-box{
	background:#1F412B url(../img/green-windmill.png) no-repeat;
	background-position: 22px 77%;
	background-size: auto 75px;
	display: block;
	color:white;
	border-radius: 20px;
	padding:20px;
	padding-bottom: 115px;
	margin:2rem 0;

	h2{
		color:white;
		font-size: 22px;
		font-weight: bold;
	    letter-spacing: 1px;
	}

	p{
		color:#95A59A;
		font-size: 15px;
		display: block;
		margin:10px 0 5px;
	}

	span{
		display: inline-block;
		margin-top:10px;
		background:url(../img/cta-green.png) no-repeat;
		background-position: center left;
		background-size: 20px 18px;
		padding-left: 30px;
		font-size: 14px;
	}

	&:hover{
		span{
			color:$c--2;
		}
	}
	@media(min-width: 650px){
		background-position:right  -957px center;
		background-size: auto 110px;
		padding: 30px;
	}
}


.upsell-promo{
	background:$c--1 url(../img/pound.png) no-repeat;
	background-position: top left;
	background-size: auto 100% ;
	display: block;
	padding:1rem;
	color:white;
	text-align: center;
	margin:2rem 0;
	&,
	& *{
		transition: all ease-out 0.2s;
	}
	
	h2{
		font-size: 20px;
	}
	span{
		display: inline-block;
		margin-top:10px;
		background:url(../img/arrow.png) no-repeat;
		background-position: center left;
		background-size: 20px 18px;
		padding-left: 30px;
		font-size: 15px;
	}

	&:hover{
		span{
			color:$c--2;
		}
	}
}




main.impression-wrap{
    display: block;
    max-width:615px;
    margin:0 auto;
	
    div.impression-content-wrap{
		padding:25px 20px;
		@media(--xs-up) {
			padding:25px 60px;
		}
	}

	
	
	.phone-cta{
		display: block;
		margin:25px 0;
		padding:25px 20px 25px 55px;
		background:#F2F3F6 url(../img/phone.png) no-repeat;
		color:$c--7;
		background-position: 20px center;
		font-size: 22px;
		span{
			font-weight: bold;
		}
	}

	.btn.btn-lg.w-full{
		@extend %btn--1;
		width:100%;
		margin-top:20px;
	}

	.hidden{
		display: none;
	}
	
	.text-grey-dark{
		color:$c--7;
	}

	.text-sm{
		font-size:15px;
		line-height: 1.5em;
		margin: 1rem 0;
		a{
			color:$c--2;
		}
	}
	

	.text-xsm{
		display: block;
		color: #9CA2B1;		
		font-size: 12px;	
		letter-spacing: -0.28px;	
		line-height: 14px;	
		text-align: center;
		font-family: $ff--1;
		margin-top: 6px;
		a{
			color:$c--2;
		}
	}


	.form-error,
	.text-red{
		color:red;
		line-height: 1.2em;
		font-size: 13px;

		a{
			color:$c--7;
		}
	}
	
	.form-error{
		position:absolute;
		bottom:-25px;
		left:0;
	}

	.text-red{
		border:solid 2px red;
		padding:12px;
		border-radius: 3px;
		background:rgba(255,8,80,0.1);
	}

	.error-wrap{
		position: relative;
		margin-bottom: 35px;
	}
	

	.address-message{
		.text-red{
			position: static;
			left:0;
			bottom:0;
			border:solid 2px red;
			padding:12px;
			border-radius: 3px;
			background:rgba(255,8,80,0.1);
		}
	}


	.form-error{
		display: block;
	}
	
	.go-back-btn{
		font-family: $ff--3;
		color:$c--7;
		font-size: responsive 18px 20px;
		font-range: 420px 1280px;
		line-height: 1.1em;
		margin-bottom: 10px;
		background:none;
		span{
			padding-left: 10px;
		}
	}

	h3.subtitle{
		font-family: $ff--3;
		color:$c--7;
		font-size: responsive 18px 26px;
		font-range: 420px 1280px;
		line-height: 1.1em;
		margin-top:2rem;
		margin-bottom: 20px;

		&.req{
			position: relative;
			padding-right: 80px;

			&:after{
				content:"*";
				position: absolute;
				right: 48px;
    			top: 0px;
				color:red;
				font-size: 13px;
				@media screen and (min-width: 850px) {
					right: 62px;
				}
			}

			&:before{
				content:"Required";
				position: absolute;
				right:0;
				top:2px;
				color: $c--4;
				font-size: responsive 11px 15px;
				font-range: 420px 1280px;
				letter-spacing: -.04em;
				font-family: $ff--1;
			}
		}
	}




	
	.form-postcode-search{
		position: relative;
		button{
			@extend %btn--1;
		}
		

		@media(--xs-down) {
			button{
				width:100%;
			}
		}

		@media(--xs-up) {
			input{
				padding-right:60px;
				width:calc(100% - 100px)!important;
			}
			button{
				position: absolute;
				top:0;
				right:0;
				bottom:0;
			}
			
		}
	}
	
	input[type="tel"].form-field,
	input[type="email"].form-field,
	input[type="text"].form-field{
		height: 62px;
		display: block;
		width:100%;
		padding-left: 15px;
		border: 1px solid #E7E8E9;
		border-radius: 7px;
		background-color: #F2F3F6;
		box-shadow: 0 22px 54px 0 rgba(0,0,0,0.09);
		margin:10px 0;
	}
	


	.dropdown{
		.dropdown-toggle{
			height: 62px;
			display: inline-flex;
			width:100%;
			padding-left: 15px;
			border: 1px solid #E7E8E9;
			border-radius: 7px;
			background-color: #F2F3F6;
			box-shadow: 0 22px 54px 0 rgba(0,0,0,0.09);
			margin:10px 0;
			
			.selected-tag,
			input[type=search]{
				height: 62px;
				line-height: 62px;
				display: block;
				width:100%;
			}

			input[type=search]{
				width: calc(100% - 50px);
			}

			.selected-tag{
				pointer-events: none;
				float: none;
			    width: 100%;
			    padding: 1.2em 4.5rem 0 0em;
			    margin: 0;
			    line-height: 1.2em;
			}

			.clear{
				bottom: 18px!important;
			}
			
			.open-indicator{
				bottom: 17px!important;
			}
		}
	}
	

	.energy-picker{

		.block{
			height: 138px;	
			width: 100%;	
			border-radius: 5px;	
			background-color: #FFFFFF;	
			box-shadow: 0 22px 54px 0 rgba(0,0,0,0.09);
			position: relative;
			margin:20px 0;
		}
		
		input{
			position: absolute 0;
			size:100%;
			z-index: 2;
			cursor: pointer;
			opacity: 0;
		}

		.active{
			opacity:0;
			position: absolute 0;
			size:100%;
			z-index: 0;
			pointer-events: none;
			border-radius: 5px;
			border: 1px solid #FC9909;

			span{
				color:white;
				text-align: center;
				border-radius: 100%;
				background:$c--2;
				size:26px;
				line-height: 26px;
				position: absolute;
				left:0;
				top:50%;
				transform: translate(-50%, -50%);
			}
		}

		input:checked + .active{
			opacity:1;
		}
		
		label{
			display: block;
			position: absolute 0;
			size:100%;
			z-index: 1;
			font-family: $ff--3;
			text-align: center;
			line-height: 215px;
			margin: 0;


			&[for="heating_oil"]{
				background: url(../img/oil.png) no-repeat center center;
				background-size: 35px auto;
				
			}

			&[for="lpg"]{
				background: url(../img/lpg.png) no-repeat center center;
				background-size: 61px auto;
			}
		}

		@media(--xs-up) {
			display:flex;
			justify-content:space-between;
			align-content:flex-start;

			.block{
				width:calc(50% - 12px);
			}
		}
	}
	

		

/*
	 border-2 border-red
	form-field border-2 border-red
	form-field border-2 border-red
*/




	.tooltip{
		text-align: right;

		a{
			color:$c--2;
			display: inline-block;
			padding:5px 0 5px 10px;
			font-size: 13px;
			cursor: pointer;
			
		}
		div{
			font-size:12px;
			line-height: 1.2em;
			overflow: hidden;
			max-height: 0vh;
			transition: all 1s easeOutCubic;
		}
		

		&.open{
			div{
				max-height: 100vh;
				transition: all 1s ease-in;
			}
		}
	}


	.lower-footer{
		border-top: solid 2px #E6E8F0;
		padding-top:10px;
		margin-top:30px;

		button {
			background-color: #EFEFEF;
		}
		button[disabled="disabled"]{
			opacity: 0.3;
		}
	}















	.block-buttons{
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		
		.carbon-offset-premium{
			span.green-tip{
				color:;
				display: inline-block;
				background:#E8F2EB;
				color:#2AC84E;
				padding:4px 5px;
				line-height: 1em;
				font-size: 10px;
				font-weight: normal;
				margin:5px 0;
				border-radius: 5px;
			}
		}

		.tooltip{
			a{
				display: block;
				margin:5px auto;
				size:20px;
				text-align: center;
				line-height: 20px;
				background:#EDEDED;
				border-radius: 100%;
				color: #001549;		
				font-size: 12px;	
				font-weight: bold;	
				letter-spacing: -0.17px;	
				line-height: 20px;
				padding:0;
			}

			div{
				display: block;
				color: #9CA2B1;		
				font-size: 12px;	
				letter-spacing: -0.28px;	
				line-height: 14px;	
				text-align: center;
				font-family: $ff--1;
				margin-top: 6px;
			}
		}


		.large-radio{

			min-height: 60px;	
			width: 100%;
			border-radius: 5px;	
			background-color: #FFFFFF;	
			box-shadow: 0 22px 54px 0 rgba(0,0,0,0.09);
			position: relative;
			margin:5px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			
			.top-label{
				display: block;
			}

			@media screen and (min-width: 420px) {
				width: 30%;
				height: 120px;	
			}
		}
		
		input{
			position: absolute 0;
			size:100%;
			z-index: 2;
			cursor: pointer;
			opacity: 0;
		}

		.highlight{
			opacity:0;
			position: absolute 0;
			size:100%;
			z-index: 20;
			pointer-events: none;
			border-radius: 5px;
			border: 1px solid #FC9909;

			span{
				color:white;
				text-align: center;
				border-radius: 100%;
				background:$c--2;
				size:26px;
				line-height: 26px;
				position: absolute;
				left:0;
				top:50%;
				transform: translate(-50%, -50%);
			}
		}
		
		.carbon-offset-premium .highlight{
			border: 1px solid #2AC84E;

			span{
				background:#1F412B;
			}
		}



		.large-radio.active .highlight,
		input:checked + .highlight{
			opacity:1;
		}
		

		input[disabled="disabled"]{
			& + span + label{
				opacity: 0.2;
			}
		}
		label{
			display: block;
			font-size: responsive 18px 20px;
			font-range: 420px 1280px;
			z-index: 1;
			font-family: $ff--3;
			text-align: center;
			line-height: 1em;
			margin: 0;
			padding:15px 10px;
			position: relative;
			z-index: 10;

			span.notice--tooltip{

			}
	/*		@media screen and (min-width: 420px) {
				padding:0px 15px;
			}*/
			.font-normal{
				display: block;
				color: #9CA2B1;		
				font-size: 12px;	
				letter-spacing: -0.28px;	
				line-height: 14px;	
				text-align: center;
				font-family: $ff--1;
				margin-top: 6px;
			}
		}

		@media(--xs-up) {
			display:flex;
			justify-content:space-between;
			align-content:flex-start;

			.block{
				width:calc(50% - 12px);
			}
		}
	}
	


	.vue-slider-container{
		margin-top:40px;
		.counter{
			display:flex;
			justify-content:space-between;
			align-content:flex-start;

			color: #413F54;	
			font-size: 13px;
		}
		
		.vue-slider-component .vue-slider-process{
			background-color: $c--2;
		}

		.vue-slider-component .vue-slider-tooltip{
			border: 1px solid #D9D9D9;	
			background-color: #EDEDED;
			height: 25px;
    		line-height: 25px;
    		font-size: 13px;
    		padding:0 5px;
    		color: #413F54;
		}

		.vue-slider-bound{
			display: none;
		}
	}
	

	div[role="alert"]{
		margin:2rem 0;
	}

	.fuel-alert-box{
		border: 1px solid #d9d9d9;
		background-color: #ededed;
		border-radius: 5px;
		padding: 15px;
		font-size:13px;
		line-height: 1.5em;


		a{
			color:$c--2;
			font-weight: bold;
		}
		
		p.text-grey-darker{
			font-size:15px;
		}

		.form-group{
			margin-top: 10px;
			.hidden{
				display: inline-block;
				position: relative;
				top:2px;
			}
		}
	}


	.quote-breakdown-wrap{
		margin-top:35px;
		background:#F2F3F7;
		border-radius: 5px;

		.block{
			padding:20px;
			&.top{
				border-bottom: solid 2px white;
			}

			&.bottom{
				border-top: solid 1px #DADBDD;
				padding-top: 20px;
			}

			&.buttons{
				padding-top: 0;
				.btn.btn-lg.w-full{
					margin-top: 0;
				}
			}
			
			@media(--sm-up){
				padding:43px 43px 35px;
			}
		}

		h4.subtitle{
			opacity: 0.33;
			color: #0C2153;	
			font-size: 13px;
			font-weight: bold;
			letter-spacing: 2.17px;
			line-height: 16px;
			text-transform: uppercase;
			margin-bottom: 10px;
		}

		h3.main-price{
			.currency{
				font-size: responsive 20px 26px;
				font-range: 420px 1280px;
				line-height: 1.1em;
				color:$c--7;
				font-family: $ff--2;
				vertical-align: top;
				position:relative;
				top:0.3em;
			}

			.total{
				font-size: responsive 40px 56px;
				font-range: 420px 1280px;
				line-height: 1.1em;
				color:$c--7;
				font-family: $ff--2;
				vertical-align: top;
			}

			.vat{
				font-size:12px;
				color:#9CA2B1;
				vertical-align: bottom;
			}
		}

		.row{
			display: flex;
			justify-content: space-between;
			align-content: flex-start;
			flex-wrap: wrap;

			&.coupon-row{
				border-top: solid 1px #DADBDD;
				padding-top:10px;
				margin-top: 10px;
			}
			.col{
				width:100%;
				margin-bottom: 5px;

				&.price{
					text-align: right;
					font-size: responsive 16px 18px;
					font-range: 420px 1280px;
					color:$c--7;
					font-family: $ff--3;
					text-align: left;
				}

				&.info{
					.overview{
						font-size: responsive 16px 18px;
						font-range: 420px 1280px;
						color:$c--7;
						line-height: 1.1em;
						letter-spacing: -0.42px;
						font-family: $ff--2;
					}

					.subinfo{
						color: #9CA2B1;	
						font-size: 12px;
						letter-spacing: -0.28px;	
						line-height: 14px;
						margin-top: 5px;
					}
				}
			}

			@media(--xs-up){
				.col{
					width:48%;
					margin-bottom: 0px;
					&.price{
						text-align: right;
					}
				}
			}
		}
	}

	.discount-code-area{
		margin:35px 0;
		background:#F2F3F7;
		border-radius: 5px;

		header{
			padding-right:35px;
			position: relative;
			padding:20px;

			span{
				display: block;
				color:$c--1;
				font-family:$ff--2;
				font-size: 18px;
				padding-right: 40px;
			}

			a.btn{

				size:40px;
				position: absolute;
				top:50%;
				transform: translateY(-50%);
				right:10px;
				cursor: pointer;
				&:before{
					content:"+";
					position: absolute;
					top:0;
					left:0;
					text-align: center;
					size:40px;
					color:$c--2;
					font-size: 30px;
					line-height: 40px;
				}
				
			}
		}
		
		.content{
			max-height: 0vh;
			overflow: hidden;
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			padding:0 20px;
			a.apply-btn{
				color:$c--2;
				line-height: 1.2em;
				display: block;
				padding:0px 0 0 10px;
				height:60px;
				line-height: 60px;
				font-family: $ff--2;
				margin-left: 10px;
				padding-left: 10px;
				cursor: pointer;
			}
			input{
				border:solid 1px #E7E8E9;
				margin:0;
				background:white;
			}
		}

		&.open{
			padding:0px 0px 20px 0px;
			.content{
				max-height: 100vh;
			}

			header{
				a.btn{
					&:before{
						content:"-";
					}
				}
			}
		}
	}
	


	.overlay--show.overlay{
	   

	    display: flex;
	    justify-content: center;
	    align-items: center;
	    flex-direction: column;
		

		h2{
			line-height: 1.1em;
			text-align: center;
			color:$c--1;
			font-size: 16px;
			font-family:$ff--2;
			margin-bottom: 1rem;
		}
		button[type="submit"]{
			cursor: pointer;
			border-radius: 5px;
			&:hover{
				color:#FC9909;
			}
		}


		button[type="submit"],
		button[type="button"]{
			line-height: 1.2em;
			font-size: 14px;
			padding:20px;
			height: auto;
			width:100%;
		}


		button[type="button"]{
			margin-bottom: 15px;
		}

		

	}

	.vs__actions {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
	}

}



section.ecom-outter-wrap{
	background: #fff;
	box-shadow: 0 22px 54px 0 rgba(0,0,0,0.09);
	border-radius: 5px;
	display: block;
	margin: 0 auto;
	max-width: 615px;
	overflow: hidden;
	width: calc(100% - 45px);
	z-index: 10;
	position: relative;

	&.steps-3-6{
		max-width: 1300px;
	}
}