/**************************************************************
* Reset
**************************************************************/

/* Target all elements */
* {
	border: 0;
	font: inherit;
	font-style: normal;
	list-style: none;
	margin: 0;
	padding: 0;
	quotes: none;
	text-decoration:none;
	vertical-align: baseline;

}

*, *:before, *:after { box-sizing: border-box; }

/* HTML document & body */
html, body {
	color: $base--text-colour $base--bg-colour;
	font-family: $base--font-family;
	
	max-width: 100%;
	min-width: 320px;
	overflow-x: hidden;

	/* Font smoothing */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Disable scrollbar */
	&::-webkit-scrollbar { display: none; }
	
}

html {
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* ReCAPTCHA */
.grecaptcha {
	display: none !important;
	opacity: 0 !important;
	visibility: hidden !important;
	z-index: -1 !important;
}

/* HTML5 Elements */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,main,summary {
	display: block;
}

/* Blockquotes */
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

/* Tables */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Images */
img {
	height: auto;
	max-width: 100%;
    vertical-align: middle;
}

/* Text area */
textarea { resize: vertical; }

/* Highlighting text in browser */
::selection {
    color: $c--highlight-text $c--highlight-bg !important;
    text-shadow: none;
}

/* Buttons */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:focus,
input:focus,
a:focus {outline:0;}
[tabindex="-1"]:focus {
	outline: none !important;
}

/* Make viewport responsive */
@-ms-viewport { width: device-width; }


/* END OF DOCUMENT */
