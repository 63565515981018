/**************************************************************
* Hero: Large
**************************************************************/

section.hero-large {
    background: #001549 url(../../images/hero-bg.svg) 60% 78px no-repeat;
    background-size: 485px auto;
    border-bottom-left-radius: 50% 45px;
    border-bottom-right-radius: 50% 45px;
    color: #fff;
    padding: 110px 30px 40px;
    text-align: center;
    margin: 0 -30px;

    @media (--md-up) {
        padding: 190px 0 340px;
        background-position: 60% 20px;
        background-size: 100% auto;
        border-bottom-left-radius: 50% 30%;
        border-bottom-right-radius: 50% 30%;
        margin-bottom: -310px;

        h1 {
            font-size: 57px;
            margin-bottom: 20px;
        }

        .desc {
            font-size: 20px;
            margin-bottom: 25px;
        }

        ul {
            li {
                font-size: 16px;
            }
        }
    }

    .wrapper {
        padding: 0 30px;
    }

    h1 {
        font-size: 23px;
        font-weight: 700;
        letter-spacing: -1%;
        margin-bottom: 25px;

        span {
            color: #FB9E09;
        }
    }

    .desc {
        font-size: 13px;
        line-height: 1.538em;
        margin: 0 auto 22px;
        max-width: 730px;
    }

    ul {
        display: flex;
        vertical-align: middle;
        font-size: 0;
        margin-bottom: 20px;
        gap: 10px 25px;
        justify-content: center;

        @media (--md-down) {
            flex-flow: column;
            align-items: center;
        }

        li {
            background: transparent url('../../images/check.svg') no-repeat top 50% left / 20px 18px;
            color: #fff;
            min-height: 18px;
            padding-left: 25px;
            font-size: 13px;
            line-height: 1.475em;

            &:not(:last-of-type) {
                margin-bottom: 2px;
            }

        }

    }

}


/* END OF DOCUMENT */
