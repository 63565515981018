/**************************************************************
* Wrappers
**************************************************************/

/* Generic wrapper */
%wrapper {
	margin: * auto;
	padding: * $gutter--mobile;
	width: 100%;
}

%wrapper--1 {
	@extend %wrapper;
	max-width: calc(1080px + calc($gutter * 4));

	@media (--sm-xl) { max-width: calc(1080px + $gutter); }

	@media (--sm-up) { padding: * $gutter; }

}

%wrapper--2 {
	@extend %wrapper;
	max-width: calc(1340px + calc($gutter * 4));

	@media (--sm-xl) { max-width: calc(1340px + $gutter); }

	@media (--sm-up) { padding: * $gutter; }

}

%wrapper--3 {
	@extend %wrapper;
	max-width: calc(1680px + calc($gutter * 4));

	@media (--sm-xl) { max-width: calc(1340px + $gutter); }

	@media (--sm-up) { padding: * $gutter; }

}

%wrapper--4 {
	@extend %wrapper;
	max-width: 870px;

	@media (--xl-up) { padding: * 0; }

	@media (--sm-xl) { padding: * $gutter; }

}

%wrapper--5 {
	@extend %wrapper;
	max-width: 1100px;

	@media (--xl-up) { padding: * 0; }

	@media (--sm-xl) { padding: * $gutter; }

}



/* END OF DOCUMENT */
