nav.progress{
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
	font-family: $ff--3;
	margin-top:20px;
	border-bottom: solid 2px #CECECE;
	padding-bottom:25px;
	font-size:16px;

	ul{
		display: flex;
		justify-content: center;
		align-items: center;
		
	}
	
	li.past-active{
		a{
			background:$c--2;
			color:white;
		}
	}

	li.active{
		color: #FFFFFF;	
		border:solid 3px $c--2;
		a{
			background:$c--2;
			color:white;
		}
	}
	

	&.step-1{
		
		
	}

	a{
		background:#F2F3F7;
		size:40px;
		display: block;
		font-size: 1.1875em;
		line-height: 2em;
		border-radius: 9999px;
		color:#CECECE;
		position: relative;

		&:before{
			height: 3px;
		    width: 25px;
		    content: "";
		    position: absolute;
		    top: 50%;
		    left: calc(100% + 10px);
		    transform: translateY(-50%);
		    z-index: 0;
		    background: #cecece;

			@media(--xs-up){
				width: 40px;
			}
		}
	}

	li.active a{
		&:before{
		    width: 25px;
		    left: calc(100% + 15px);
			@media(--xs-up){
				width: 40px;
			}
		}
	}

	li.past-active a{
		&:before{
			background:$c--2;
		}
	}

	li{
		border-radius: 9999px;
		letter-spacing: -0.16px;
		background:white;
		border:solid 3px white;
		padding:5px;
		margin-right:35px;
		position: relative;
		z-index: 3;
		&:last-child{
			a:before{
				display:none;
			}
			margin:0;
		}
	}

	@media(--xs-up){
		a{
			size:60px;
			font-size: 1.4375em;
			line-height: 2.6em;
		}

		li{
			margin-right:55px;
		}

	}
}