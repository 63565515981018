/**************************************************************
* Footer
**************************************************************/

footer.primary {
	color: #fff $c--7;
	display: block;
	margin-top:6rem;

	/* Container */
	.container { @extend %wrapper--2; }

		/* Column labels */
		span.col-label {
			@extend %ts--11;
			display: block;
			letter-spacing: .15em;
			margin-bottom: 10px;
			text-transform: uppercase;
		}

		/* Anchors */
		a {
			color: #fff;
			display: inline-block;
			text-decoration: none;
			transition: all .2s ease-in-out-quart;

			&:hover { color: $c--2; }

		}

		/**`
		* Top pane
		*/
		.contact-pane {
			border-bottom: 1px solid rgba(255,255,255,.12);
			display: block;
			padding: 48px 0 40px;

			/* Column */
			.column { display: block; }

				/* Content */
				.col-content { @extend %ts--12; }
			
			/* Label */
			.column.label {
				@extend %ts--10, %ff--3;
				margin-bottom: 30px;
				text-align: center;
			}

			/* Telephone */
			.column.telephone {

				.col-content {
					background: transparent url('../img/footer-phone.svg') no-repeat top 4px left / 16px 22px;
					color: rgba(255,255,255,.44);
					padding-left: 34px;
				}

					ul.numbers li .label {
						display: inline-block;
						min-width: 44px;
					}
				
			}

			/* Email */
			.column.email {

				.col-content {
					background: transparent url('../img/footer-email.svg') no-repeat top 4px left / 22px 15px;
					padding-left: 38px;
				}
				
			}

			/* Support */
			.column.support {

				.col-content {
					background: transparent url('../img/footer-support.svg') no-repeat top 3px left / 24px 19px;
					padding-left: 38px;
				}
				
			}

			@media (--xxl-up) { padding: * $gutter; }

			@media (--xl-up) {
				flex: nowrap;
			
				/* Label */
				.column.label {
				    align-self: center;
				    margin-bottom: 0;
					text-align: left;
				}

			}

			@media (--lg-xl) {

				/* Other columns (not label) */
				.column:not(.label) {
					flex: 0 0 33.3333%;
					max-width: 33.3333%;
					width: 33.3333%;
				}

			}

			@media (--md-xl) {
				flex-wrap: wrap;

				/* Label */
				.column.label {
					display: block;
					flex: 1 1 100%;
					width: 100%;
				}

			}

			@media (--md-up) {
				align-items: stretch;
				display: flex;
				justify-content: space-between;
				padding: 30px 0;

				/* Column */
				.column {
					display: inline-block;
					padding: 12px *;
					vertical-align: top;
				}

				/* Separators */
				.column.email, .column.support {
					border-left: 1px solid rgba(255,255,255,.12);
					padding-left: 30px;
				}

			}

			@media (--md-down) {

				/* Column seperators */
				.column:nth-of-type(n+3) {
					margin-top: 30px;
					padding-top: 30px;
					position: relative;

					&::before {
						background: rgba(255,255,255,.12);
						content: "";
						margin: * auto;
						position: absolute 0 0 *;
						size: 100px 1px;
						text-align: center;
					}

				}

			}

		}

		/**
		* Main pane
		*/
		.main-pane {
			display: block;
			padding: 40px 0 50px;
			text-align: center;
			
			/* Label */
			.col-label { margin-bottom: 15px; }
			
			/* Content */
			.col-content {
				@extend %ts--9;
				color: rgba(255,255,255,.44);
			}

				/* Anchors */
				a:not(:hover) { color: rgba(255,255,255,.44); }

				/* Address */
				address { font-style: normal; }

			/* Other columns (not emergencies) */
			.column:not(.emergencies) {
				margin-bottom: 30px;
				padding-top: 12px;
			}

			/* Emergencies */
			.column.emergencies {
				padding-top: 10px;
				
				/* Number */
				a.emergency-inner {
					color: #fff rgba(21,40,90,.75);
					display: inline-block;
					margin: * auto;
					min-width: 206px;
					padding: 20px 45px 25px;
					transition: all .2s ease-in-out-quart;

					&:hover {
						background: rgba(21,40,90,.55);

						.number { color: $c--2; }

					}

				}

					.number {
						@extend %ts--13;
						background: transparent url('../img/footer-phone.svg') no-repeat top 4px left / 16px 22px;
						min-height: 22px;
						padding-left: 28px;
						transition: all .2s ease-in-out-quart;
					}

				/* Logos */
				img.logos {
					display: block;
					margin: 30px auto 0;
					max-width: 220px;
				}

			}

			@media (--xxl-up) { padding: * $gutter; }

			@media (--md-up) {
				align-items: stretch;
				border-bottom: 1px solid rgba(255,255,255,.12);
				display: flex;
				flex: wrap;
				justify-content: space-between;
				padding-bottom: 60px;
				text-align: left;

				/* Other columns (not emergencies) */
				.column:not(.emergencies) { margin-bottom: 0; }

				/* Emergencies */
				.column.emergencies {
				    align-items: flex-end;
				    display: inline-flex;
				    flex-direction: column;
				    justify-content: space-between;
					padding-top: 0;
					text-align: right;

					> a.emergency-inner {
						margin: 0;
						padding: * 22px;
						text-align: left;
					}

					/* Logos */
					img.logos {
						align-self: flex-end;
						margin: 30px 0 0;
					}

				}

			}

		}

		/**
		* Bottom pane
		*/
		.bottom-pane {
			@extend %ts--9;
			align-content: flex-start;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding: 0 0 35px;
			text-align: center;

			/* Anchor */
			a:not(:hover) { color: $c--2; }

			/* Copyright */
			.copyright {
				flex: 1 1 100%;
				order: 1;

				.credit { display: block; }

			}

			/* Social */
			.social {
				border-bottom: 1px solid rgba(255,255,255,.12);
				flex: 1 1 100%;
				margin: 0 auto 30px;
				order: 0;
				padding-bottom: 40px;

				li:not(:first-of-type) { margin-left: 20px; }

			}

			@media (--xxl-up) { padding: * $gutter; }

			@media (--md-up) {
				align-items: center;
				flex: nowrap;
				justify-content: space-between;
				padding-top: 35px;
				text-align: left;

				/* Copyright */
				.copyright {
					flex: 1 1 auto;
					order: 0;

					.credit { display: inline-block; }

				}

				/* Social */
				.social {
					border-bottom: 0 none;
					flex: 1 1 auto;
					margin: 0;
					order: 1;
					padding: 0;
					text-align: right !important;

					li a:hover svg path { fill: $c--2; }

				}

			}

		}

	/***************
	* Lazy-loading
	***************/
	&.view-detect {
		opacity: 0;
		transition: all 1.2s ease-in-out-quart .2s;
	}

	/**
	* Loaded
	*/
	&.view-detect.viewed { opacity: 1; }

}


/* END OF DOCUMENT */
