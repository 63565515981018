/**************************************************************
* Header
**************************************************************/

header.primary {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: background 400ms ease-in-out;
    padding: 20px;

    .back-button {
        @extend %btn--2;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        padding: 0 14px;
        min-height: 32px;
        font-size: 12px;
        color: #fff;

        span {
            display: none;
        }
    }

    .logo {
        display: block;
        margin: 0 auto;
        width: 64px;

        svg {
            display: block;
            width: 100%;
        }
    }

    @media (--md-up) {
        padding: 37px;

        .back-button {
            min-height: 50px;
            padding: 0 24px;
            left: 60px;

            span {
                display: inline;
            }
        }

        .logo {
            width: 106px;
        }
    }
}


/* END OF DOCUMENT */
