/**************************************************************
* Component example
**************************************************************/

article.post {
    display: block;
}


/* END OF DOCUMENT */
